import { useAppSelector } from "@store/store-helper";
import { getProjectByIdSelector } from "@store/projects/projects-selector";
import { ListItemText } from "@mui/material";
import { FetchingItem } from "@store/table/table-slice-helper";
import { defaultProjectThumbnail } from "@utils/project-utils";

interface Props {
  /** The unique ID of the item that is being requested to do action on */
  item: FetchingItem;
}

/** List item in bulk action dialog for projects */
export function ProjectListContent({ item }: Props): JSX.Element | null {
  const selectedProject = useAppSelector(getProjectByIdSelector(item.id));

  if (selectedProject === null) {
    return null;
  }

  return (
    <>
      <img
        src={selectedProject.thumbnailUrl ?? defaultProjectThumbnail}
        style={{
          height: "40px",
          width: "56px",
          maxHeight: "100%",
          maxWidth: "100%",
          objectFit: "contain",
          borderRadius: "8px",
        }}
        alt="Project thumbnail"
      />

      <ListItemText primary={<var>{selectedProject.name}</var>} />
    </>
  );
}
