import { SphereAvatar } from "@components/header/sphere-avatar";
import { useAppSelector } from "@store/store-helper";
import { companyMemberSelector } from "@store/members/members-selector";
import {
  getPrettyName,
  getUserInitials,
  isMemberActive,
} from "@utils/user-utils";
import { ListItemText } from "@mui/material";
import { DASH } from "@utils/ui-utils";
import { FetchingItem } from "@store/table/table-slice-helper";
import { getTeamByIdSelector } from "@store/teams/teams-selector";
import { sphereColors } from "@styles/common-colors";
import Team from "@assets/icons/new/team.svg?react";
import { isTeam } from "@utils/team-utils";

interface Props {
  /** The unique ID of the item that is being requested to do action on */
  item: FetchingItem;
}

/** List item in bulk action dialog for members */
export function MemberListContent({ item }: Props): JSX.Element | null {
  const companyMember = useAppSelector(companyMemberSelector(item.id));
  const selectedTeam = useAppSelector(getTeamByIdSelector(item.id));

  if (!companyMember && !selectedTeam) {
    return null;
  }

  return (
    <>
      {companyMember && !isTeam(companyMember) ? (
        <SphereAvatar
          shouldHideWhiteRim
          src={companyMember.thumbnailUrl}
          initials={getUserInitials(companyMember)}
        />
      ) : (
        <SphereAvatar
          icon={<Team />}
          size="small"
          shouldHideWhiteRim={true}
          backgroundColor={sphereColors.gray700}
        />
      )}

      {companyMember && !isTeam(companyMember) ? (
        <ListItemText
          primary={
            isMemberActive(companyMember) ? (
              <var>{getPrettyName(companyMember)}</var>
            ) : (
              DASH
            )
          }
          secondary={<var>{companyMember.email}</var>}
        />
      ) : (
        <ListItemText
          primary={<var>{selectedTeam?.name ?? ""}</var>}
          secondary={`${selectedTeam?.memberCount ?? 0} members`}
        />
      )}
    </>
  );
}
