import { SphereAvatar } from "@components/header/sphere-avatar";
import { useAppSelector } from "@store/store-helper";
import { getTeamByIdSelector } from "@store/teams/teams-selector";
import { ListItemText } from "@mui/material";
import { FetchingItem } from "@store/table/table-slice-helper";
import Team from "@assets/icons/new/team.svg?react";
import { sphereColors } from "@styles/common-colors";

interface Props {
  /** The unique ID of the item that is being requested to do action on */
  item: FetchingItem;
}

/** List item in bulk action dialog for teams */
export function TeamListContent({ item }: Props): JSX.Element | null {
  const team = useAppSelector(getTeamByIdSelector(item.id));

  if (team === undefined) {
    return null;
  }

  return (
    <>
      <SphereAvatar
        icon={<Team />}
        backgroundColor={sphereColors.gray700}
        shouldHideWhiteRim={true}
      />
      <ListItemText
        primary={<var>{team.name}</var>}
        secondary={`${team.memberCount} members`}
      />
    </>
  );
}
